
export const GET_SECTION_NAME = "GET_SECTION_NAME";
export const GET_SECTION_SHIFT = "GET_SECTION_SHIFT";
export const GET_REMOTE_ATTENDANCE_EMPLOYEE = "GET_REMOTE_ATTENDANCE_EMPLOYEE";
export const REMOTE_CHECKBOX_STATUS = "REMOTE_CHECKBOX_STATUS";
export const REMOTE_CHECKBOX_ALL = "REMOTE_CHECKBOX_ALL";
export const GET_REMOTE_ATTENDANCE_STATUS = "GET_REMOTE_ATTENDANCE_STATUS";

/*
 * Movement Types
*/
export const GET_USER_INFORMATION = "GET_USER_INFORMATION";
export const GET_FORM_RESET = "GET_FORM_RESET";
export const GET_INPUT_HANDLE = "GET_INPUT_HANDLE";
export const SELECT_HANDLE_SEARCH = "SELECT_HANDLE_SEARCH";


/*
 * Leave
*/
export const HANDLE_LEAVE_INPUT = "HANDLE_LEAVE_INPUT";
export const GET_ZONE_DATA = "GET_ZONE_DATA";
export const GET_AREA_DATA_BY_ZONE = "GET_AREA_DATA_BY_ZONE";
export const GET_AREA_LIST_BY_AREA = "GET_AREA_LIST_BY_AREA";
