export const CHANGE_VALUE_INPUT = "CHANGE_VALUE_INPUT";
export const DATA_SUBMIT = "DATA_SUBMIT";
export const CLEAR_FIELD = "CLEAR_FIELD";
export const GET_BUYER_CATEGORY_TYPE = "GET_BUYER_CATEGORY_TYPE";
export const BUYER_DETAILS = "BUYER_DETAILS";
export const CHANGE_VALUE_INPUT_SMS = "CHANGE_VALUE_INPUT_SMS";
export const BUYER_DETAILS_FOR_SMS = "BUYER_DETAILS_FOR_SMS";
export const SMS_SEND_FROM_IMS = "SMS_SEND_FROM_IMS";
export const DELETE_BUYER_LIST = "DELETE_BUYER_LIST";
export const CHANGE_VALUE_INPUT_SMS_TEM = "CHANGE_VALUE_INPUT_SMS_TEM";
export const GET_BUYER_UNIT_TYPE = "GET_BUYER_UNIT_TYPE";
export const TEMPLATE_SUBMIT = "TEMPLATE_SUBMIT";
export const TEMPLATE_LIST = "TEMPLATE_LIST";
export const GET_USER_ROLE_INPUT_DATA = "GET_USER_ROLE_INPUT_DATA";
export const GET_USER_ROLE_TYPE = "GET_USER_ROLE_TYPE";