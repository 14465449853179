
import * as Types from "../../SMS System/types/Types";

const initialState = {
    rollbacklist: [],
    rollbackData: [],
    allCheckbox: false,
    buyerLoading: false,
    smsStatus: false,
    unapprovedList: [],
    allReport: [],
    fundList: [],
    workingdayList: [],
    distibListforsms: [],
    buyerCatType: [],
    buyerUnitType:[],
    buyerData: [],
    smsStatus: false,
    itemDataInput: {
        buyer_name: "",
        address: "",
        email: "",
        contact_Number: "",
        buyer_Category_id: 0,
        whats_app_number: "",
        unitId: 0,
    },
    itemDataInputSMSTEM: {
        template_name: "",
        description: ""

    },
    dataInputSMS: {
        Category: null,
        unit: null
    },
    buyerSMSLoading: false,
    buyerDataForSMS: [],
    inputData: {
        UserID:null,
        UserName:null,
        Password:null,
        contactNo:null,
        RoleID:2

    },
};
const SmsReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {

        case Types.GET_USER_ROLE_INPUT_DATA:
            const roleInputData = { ...state.inputData };
            roleInputData[action.payload.name] = action.payload.value;
            return {
                ...state,
                inputData: roleInputData,
                isLoading: action.payload.isLoading,
            };

        case Types.CHANGE_VALUE_INPUT:
            const itemDataInput = { ...state.itemDataInput };
            itemDataInput[action.payload.name] = action.payload.value;
            console.log('itemDataInput :>> ', itemDataInput);
            return {
                ...state,
                itemDataInput,
            };
        case Types.CLEAR_FIELD:
            const changedvalue = {
                ...state.itemDataInput,
            };
            changedvalue.buyer_name = ""
            changedvalue.address = ""
            changedvalue.email = ""
            changedvalue.contact_Number = ""
            changedvalue.buyer_Category_id = 0
            changedvalue.whats_app_number = ""
            return {
                ...state,
                itemDataInput: changedvalue,
            };
        case Types.DATA_SUBMIT:
            return {
                ...state,
            };
        case Types.TEMPLATE_SUBMIT:
            return {
                ...state,
            };
        case Types.GET_BUYER_CATEGORY_TYPE:
            return {
                ...state,
                buyerCatType: getBUCATETYPE(action.payload.data),
            };
        case Types.GET_BUYER_UNIT_TYPE:
            return {
                ...state,
                buyerUnitType: getBuyerUnit(action.payload.data),
            };
        case Types.BUYER_DETAILS:
            console.log('buyer action.payload.data :>> ', action.payload.data);
            return {
                ...state,
                buyerData: action.payload.data,
                buyerLoading: action.payload.isLoading,
            }
        case Types.BUYER_DETAILS_FOR_SMS:
            console.log('buyerSMS action.payload.data :>> ', action.payload.data);
            return {
                ...state,
                buyerDataForSMS: action.payload.data,
                buyerSMSLoading: action.payload.isLoading,

            }
        case Types.TEMPLATE_LIST:
            return {
                ...state,
                templateData: action.payload.data,

            }
        case Types.CHANGE_VALUE_INPUT_SMS:
            const itemDataInputSMs = { ...state.dataInputSMS };
            itemDataInputSMs[action.payload.name] = action.payload.value;
            return {
                ...state,
                dataInputSMS: itemDataInputSMs,
            };
        case Types.CHANGE_VALUE_INPUT_SMS_TEM:
            const itemDataInputSMSTEM = { ...state.itemDataInputSMSTEM };
            itemDataInputSMSTEM[action.payload.name] = action.payload.value;
            return {
                ...state,
                itemDataInputSMSTEM,
            };
        case Types.SMS_SEND_FROM_IMS:
            return {
                ...state,
                smsStatus: action.payload.status

            };
        case Types.DELETE_BUYER_LIST:
            return {
                ...state,
            };

        default:
            break;
    }
    return newState;
};

export default SmsReducer;

const getBUCATETYPE = (data) => {
    let options = [];
    if (data) {
        data.forEach((item) => {
            let itemData = {
                value: item.categoryId,
                label: item.categoryName,
            };
            options.push(itemData);
        });
    }
    return options;
};
const getBuyerUnit = (data) => {
    let options = [];
    if (data) {
        data.forEach((item) => {
            let itemData = {
                value: item.unitId,
                label: item.categoryName,
            };
            options.push(itemData);
        });
    }
    return options;
};

